import { makeStyles } from 'tss-react/mui';

import { MOBILE_BREAK_POINT } from 'app/constants';

export default makeStyles({ name: 'Features' })((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 80,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            gap: 40
        }
    },
    title: {
        margin: 0,
        color: '#232323',
        fontSize: 48,
        fontWeight: 700,
        lineHeight: '120%',
        textAlign: 'center',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            fontSize: 32,
            textAlign: 'start'
        }
    },
    features: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'stretch',
        justifyContent: 'center',
        gap: 32,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            gap: 20
        }
    }
}));

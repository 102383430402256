interface Options {
    color?: string;
    thickness?: number;
    mx?: number;
    my?: number;
    visible?: 'always' | 'auto';
}

export default function macOsScrollBar(options?: Options) {
    const color = options?.color ?? '#E0E0E0';
    const my = options?.my ?? 0;
    const mx = options?.mx ?? 0;
    const thickness = options?.thickness ?? 6;
    const visible = options?.visible ?? 'auto';

    return {
        overflow: 'auto',
        scrollbarGutter: 'stable both-edges',
        ['&::-webkit-scrollbar']: {
            width: thickness + 2 * mx,
            height: thickness + 2 * my
        },
        ['&::-webkit-scrollbar-track']: {
            background: 'transparent'
        },
        ['&::-webkit-scrollbar-track:vertical']: {
            marginTop: my,
            marginBottom: my
        },
        ['&::-webkit-scrollbar-track:horizontal']: {
            marginLeft: mx,
            marginRight: mx
        },
        ['&::-webkit-scrollbar-thumb']: {
            borderStyle: 'solid',
            borderColor: 'rgba(0, 0, 0, 0)',
            backgroundClip: 'padding-box',
            backgroundColor: color,

            visibility: { auto: 'hidden', always: 'visible' }[visible] as 'hidden' | 'visible'
        },
        ['&::-webkit-scrollbar-thumb:vertical']: {
            borderWidth: mx,
            borderRadius: thickness / 2 + mx
        },
        ['&::-webkit-scrollbar-thumb:horizontal']: {
            borderWidth: my,
            borderRadius: thickness / 2 + my
        },
        ['&:hover::-webkit-scrollbar-thumb']: {
            visibility: 'visible'
        },
        ['&::-webkit-scrollbar-button']: {
            display: 'none'
        }
    } as const;
}

import React from 'react';

import { Button } from '@mui/material';

import { BOOK_LIVE_DEMO_URL } from 'app/constants';
import ApiAdvertisingItem from 'pages/landing/v2/Main/components/ApiAdvertising/components/ApiAdvertisingItem';
import ApiAdvertisingTitle from 'pages/landing/v2/Main/components/ApiAdvertising/components/ApiAdvertisingTitle';

import background from './assets/background.png?file';
import PowerIcon from './assets/power.svg';
import RefreshIcon from './assets/refresh.svg';
import ThunderIcon from './assets/thunder.svg';
import useStyles from './styles';

interface Props {
    className?: string;
    classes?: {
        root?: string;
        content?: string;
    };
}

const ApiAdvertising: React.FC<Props> = ({ classes, className }) => {
    const { classes: internalClasses, cx } = useStyles({ background });

    return (
        <div className={cx(internalClasses.root, classes?.root, className)}>
            <div className={cx(internalClasses.content, classes?.content)}>
                <ApiAdvertisingTitle
                    className={internalClasses.title}
                    title="Start using an API-first vulnerability intelligence platform"
                    subtitle="Designed for cybersecurity professionals like you"
                />
                <ApiAdvertisingItem
                    className={internalClasses.itemA}
                    icon={<PowerIcon />}
                    title="Power Your Products and Operations"
                    description="Whether you're developing cybersecurity products or running an MSSP operation, our normalized and correlated vulnerability intelligence data streamlines your processes, all in a machine-readable format"
                />
                <ApiAdvertisingItem
                    className={internalClasses.itemB}
                    icon={<ThunderIcon />}
                    title="Supercharge Your Vulnerability Management"
                    description="Don't just rely on CVSS scores. We provide rich context to help you prioritize remediation efforts, ensuring maximum impact on risk reduction"
                />
                <ApiAdvertisingItem
                    className={internalClasses.itemC}
                    icon={<RefreshIcon />}
                    title="Stay Ahead of Threats"
                    description="Keep your defenses robust and your offensive strategies informed with our real-time updates on the latest exploits and vulnerabilities"
                />
                <div className={internalClasses.buttonWrapper}>
                    <Button variant="contained" className={internalClasses.button} href={BOOK_LIVE_DEMO_URL}>
                        {'Book a live demo'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ApiAdvertising;

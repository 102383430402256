import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

import { Button, Link, useMediaQuery } from '@mui/material';

import useStores from 'hooks/use-stores';

import BulletinWidget from './components/BulletinWidget';
import { ALLOWED_TYPES } from './constants';
import useStyles from './styles';

interface Props {
    breakpoint?: number;
    classes?: {
        root?: string;
        title?: string;
        button?: string;
    };
    className?: string;
}

const BulletinTypes: React.FC<Props> = ({ breakpoint, classes, className }) => {
    const { classes: internalClasses, cx } = useStyles({ breakpoint });
    const { statsStore } = useStores();

    const isBreakpoint = useMediaQuery(`@media (max-width: ${breakpoint}px)`);

    const bulletins = useMemo(() => {
        return Object.entries(statsStore.types)
            .filter(([key]) => ALLOWED_TYPES.includes(key))
            .slice(0, isBreakpoint ? 7 : undefined)
            .map(([key, value]) => {
                const { displayName = '', count = 0 } = value as { displayName?: string; count?: number };
                return {
                    type: key,
                    name: displayName.replace(/^@.+\s*?\((.+)\)$/g, '$1'),
                    count
                };
            });
    }, [isBreakpoint, statsStore.types]);

    const button = (
        <Button
            variant="contained"
            disableElevation
            disableRipple
            className={cx(internalClasses.button, classes?.button)}
            href={'/stats'}
            target={'_blank'}
        >
            {'Browse all'}
        </Button>
    );

    return (
        <div className={cx(internalClasses.container, classes?.root, className)}>
            <div className={internalClasses.header}>
                <p className={cx(internalClasses.title, classes?.title)}>
                    {'Top security '}
                    <Link href="/search/vendors" target="_blank">
                        {'vendors'}
                    </Link>
                    {', '}
                    <Link href="/blog" target="_blank">
                        {'articles'}
                    </Link>
                    {' and '}
                    <Link href="/glossary" target="_blank">
                        {'databases'}
                    </Link>
                </p>
                {isBreakpoint ? null : button}
            </div>
            <div className={internalClasses.bulletins}>
                {bulletins.map(({ type, name, count }) => (
                    <BulletinWidget
                        key={type}
                        type={type}
                        name={name}
                        count={count}
                        className={internalClasses.bulletin}
                    />
                ))}
            </div>
            {isBreakpoint ? button : null}
        </div>
    );
};

export default observer(BulletinTypes);

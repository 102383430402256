export const ALLOWED_TYPES = [
    'nessus',
    'mskb',
    'kitploit',
    'cve',
    'hackerone',
    'zdt',
    'nmap',
    'thn',
    'threatpost',
    'ibm',
    'osv',
    'openvas',
    'openbugbounty',
    'github',
    'rapid7blog',
    'githubexploit',
    'exploitdb',
    'packetstorm',
    'mscve',
    'attackerkb',
    'msupdate',
    'ubuntu',
    'qualysblog',
    'redhat',
    'redhatcve',
    'n0where'
];

import React from 'react';
import {Link} from 'react-router-dom';

import useStyles from './styles';

interface Props {
    title: string;
    description: string;
    link: string;
    reload?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    className?: string;
    "data-testid"?: string;
}

const SubMenuItem: React.FC<Props> = ({title, description, link, reload, onClick, className, ...rest}) => {
    const {classes, cx} = useStyles();

    const common = {
        children: (
            <>
                <span className={classes.primary}>{title}</span>
                <span className={classes.secondary}>{description}</span>
            </>
        ),
        onClick,
        className: cx(classes.root, className),
        'data-testid': rest['data-testid']
    };

    return reload ? <a href={link} {...common} target="_blank"/> : <Link to={link} {...common} />;
};

export default SubMenuItem;

import React from 'react';

import { Divider } from '@mui/material';

import { MOBILE_BREAK_POINT } from 'app/constants';
import Footer from 'main/components/Footer';
import BulletinTypes from 'pages/BulletinTypes';

import ApiAdvertising from './components/ApiAdvertising';
import ApiReference from './components/ApiReference';
import Blog from './components/Blog';
import Customers from './components/Customers';
import Features from './components/Features';
import Header from './components/Header';
import InfoGraphic from './components/InfoGraphic';
import Insights from './components/Insights';
import Search from './components/Search';
import useStyles from './styles';

const Main: React.FC = () => {
    const { classes, cx } = useStyles();
    return (
        <div className={classes.root}>
            <Header className={cx(classes.section, classes.header)} />
            <Divider className={classes.divider} />
            <Search className={cx(classes.section)} />
            <InfoGraphic className={cx(classes.section, classes.stretch)} />
            <Features className={cx(classes.section)} />
            <BulletinTypes className={cx(classes.section)} breakpoint={MOBILE_BREAK_POINT} />
            <ApiReference className={cx(classes.section)} />
            <Insights className={cx(classes.section)} />
            <Blog className={cx(classes.section)} />
            <ApiAdvertising className={cx(classes.section, classes.stretch, classes.gutterless)} />
            <Customers className={cx(classes.section, classes.stretch)} />
            <Footer className={cx(classes.section, classes.footer)} />
        </div>
    );
};

export default Main;

import { makeStyles } from 'tss-react/mui';

import { MOBILE_BREAK_POINT } from 'app/constants';

export default makeStyles({ name: 'SearchBar' })((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        borderRadius: 100,
        border: '1px solid #DEE1E2',
        background: '#FFF'
    },
    autocomplete: {
        flex: 1,
        padding: '8px 24px',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            padding: '4px 16px'
        }
    },
    input: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '16px',
        padding: '8px 0',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '150%',
            padding: '4px 0'
        }
    },
    list: {
        marginTop: 8
    },
    icon: {
        position: 'absolute',
        right: 16,
        color: '#8E8E8E'
    }
}));

import { makeStyles } from 'tss-react/mui';

import { MOBILE_BREAK_POINT } from 'app/constants';

export default makeStyles<void>({ name: 'CaseItem' })((theme) => ({
    root: {
        position: 'relative',
        boxSizing: 'border-box',
        width: 290,
        cursor: 'pointer',
        flex: 1,
        padding: '20px 24px',
        borderRadius: 24,
        border: '1px solid #DEE1E2',
        display: 'flex',
        gap: 12,
        transition: 'all 0.2s',
        [`&:hover`]: {
            background: '#fff',
            opacity: 0.5
        },
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            flex: '1 0 75%'
        }
    },
    rootSelected: {
        boxShadow: '0px 10px 40px 0px rgba(176, 178, 186, 0.20)',
        background: '#fff',
        opacity: 1
    },
    title: {
        flex: 1,
        color: '#232323',
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '150%',
        alignSelf: 'center'
    },
    titleSelected: {
        fontWeight: 600
    },
    index: {
        position: 'absolute',
        top: 8,
        left: 8,
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#E2E5E6',
        padding: 4,
        borderRadius: 100,
        color: '#8E8E8E',
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '24px',
        transition: 'all 0.2s'
    },
    indexSelected: {
        background: '#151515',
        color: '#fff'
    }
}));

import { makeStyles } from 'tss-react/mui';

import { MOBILE_BREAK_POINT } from 'app/constants';

export default makeStyles({ name: 'Logo' })((theme) => ({
    root: {
        width: 42,
        height: 48,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            width: 35,
            height: 40
        }
    },
    image: {
        width: '100%',
        height: '100%'
    }
}));

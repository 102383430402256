import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'BulletinWidget' })({
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        borderRadius: 24,
        border: '1px solid #ECECEC',
        background: '#FFF',
        transition: 'all 0.3s',
        padding: 20,
        boxSizing: 'border-box',
        ['&:hover']: {
            boxShadow: '0px 8px 16px 0px rgba(176, 178, 186, 0.10), 0px 12px 32px 0px rgba(176, 178, 186, 0.25)'
        }
    },
    image: {
        width: 48,
        height: 48,
        borderRadius: 12
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        flex: 1,
        ['& a']: {
            textDecoration: 'none'
        }
    },
    name: {
        color: '#232323',
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '150%'
    },
    count: {
        color: '#8E8E8E',
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '16px'
    },
    button: {
        padding: 8,
        borderRadius: 12,
        border: '1px solid #ECECEC',
        ['& svg']: {
            width: 16,
            height: 16
        }
    }
});

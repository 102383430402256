import React, { PropsWithChildren } from 'react';

import { List, ListSubheader } from '@mui/material';

import useStyles from './styles';

interface Props {
    title?: string;
    className?: string;
}

const SubMenuGroup: React.FC<PropsWithChildren<Props>> = ({ title, className, children }) => {
    const { classes, cx } = useStyles();
    return (
        <List
            subheader={
                title ? (
                    <ListSubheader disableSticky disableGutters className={classes.title}>
                        {title}
                    </ListSubheader>
                ) : null
            }
            className={cx(classes.root, className)}
        >
            {children}
        </List>
    );
};

export default SubMenuGroup;

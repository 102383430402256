import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ breakpoint?: number }>({ name: 'BulletinTypes' })(
    (theme, { breakpoint = theme.breakpoints.values.sm }) => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 32
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        title: {
            margin: 0,
            color: '#232323',
            fontSize: 48,
            fontWeight: 700,
            lineHeight: '120%',
            ['& a']: {
                textDecoration: 'none',
                color: '#F5726A'
            },
            [theme.breakpoints.down(breakpoint)]: {
                fontSize: 24
            }
        },
        bulletins: {
            display: 'grid',
            gap: 24,
            gridTemplateColumns: 'repeat(3, 1fr)',
            alignItems: 'stretch',
            [theme.breakpoints.down(breakpoint)]: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 24,
                width: '100%'
            }
        },
        bulletin: {
            [theme.breakpoints.down(breakpoint)]: {
                width: '100%'
            }
        },
        button: {
            padding: '20px 24px',
            background: '#232323 !important',
            color: '#fff',
            fontSize: 18,
            fontWeight: 600,
            lineHeight: '18px',
            borderRadius: 16,
            whiteSpace: 'nowrap',
            [theme.breakpoints.down(breakpoint)]: {
                padding: 16,
                fontSize: 16,
                fontWeight: 500,
                lineHeight: '16px'
            }
        }
    })
);

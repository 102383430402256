import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { Fade, Tooltip } from '@mui/material';

import useStyles from './styles';

interface Props {
    title: string;
    link?: string;
    reload?: boolean;
    accent?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    className?: string;
    "data-testid"?: string;
}

const MenuItem: React.FC<PropsWithChildren<Props>> = ({
    title,
    link,
    reload,
    accent,
    children,
    onClick,
    className,
    ...rest

}) => {
    const { classes, cx } = useStyles();
    const common = {
        children: (
            <>
                {title}
                {accent ? <span className={classes.accent} /> : null}
            </>
        ),
        onClick,
        className: cx(classes.root, className),
        'data-testid':rest['data-testid']
    };

    return (
        <Tooltip
            placement="bottom"
            enterDelay={0}
            leaveDelay={100}
            TransitionComponent={Fade}
            title={children ? children : null}
            classes={{ tooltip: classes.tooltip, popper: classes.popper }}
        >
            {reload ? <a href={link} {...common} /> : <Link to={link ?? '#'} {...common} />}
        </Tooltip>
    );
};

export default MenuItem;

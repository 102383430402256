import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

import API from 'app/API';

import useStyles from './styles';

interface Props {
    open: boolean;
    currentLicense?: string[];
    validLicense?: string[];
    onClose: () => void;
}

const TrialLicenseDialog: React.FC<Props> = ({ open, currentLicense = [], validLicense = [], onClose }) => {
    const { classes } = useStyles();
    const [loading, setLoading] = useState(false);
    const [trialMessage, setTrialMessage] = useState('');

    const handleStartTrial = useCallback(() => {
        setLoading(true);
        API.fetchWithToken(API.LICENSE_START_TRIAL).then(({ result, errorMessage }) => {
            setTrialMessage(
                result === 'OK' ? `Trial successfully activated!\n\nNow you can download archive` : errorMessage
            );

            setTimeout(() => {
                setLoading(false);
                if (result === 'OK') {
                    API.fetch(API.USER_INFO_UPDATE);
                }
            }, 1000);
        });
    }, []);

    const hasTrial = currentLicense.indexOf('trial') >= 0;

    const getForm = () => (
        <DialogContentText>
            <div style={{ display: 'flex' }}>
                {hasTrial ? null : <img style={{ height: 200, marginRight: 32 }} src="/static/img/common/ticket.png" />}
                <div>
                    <p>{'The Archive Download is available only for license types'}</p>
                    <div>
                        <ul>
                            {validLicense.map((item) => (
                                <li className={classes.license} key={item}>
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <span>
                            {'Current License '}
                            {currentLicense.map((item) => (
                                <span className={classes.license} key={item}>
                                    {item}&nbsp;
                                </span>
                            ))}
                        </span>
                    </div>
                    <br />
                </div>
            </div>

            {hasTrial ? null : (
                <div>
                    {'Try Vulners paid functionality right now with 30 days Trial period. Or follow to checkout page.'}
                </div>
            )}
        </DialogContentText>
    );

    const getMessage = () => <DialogContentText>{trialMessage}</DialogContentText>;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <h2 style={{ textAlign: 'center' }}>License Request</h2>

                {loading ? <CircularProgress /> : trialMessage ? getMessage() : getForm()}
            </DialogContent>

            <DialogActions>
                {!trialMessage && !hasTrial && (
                    <Button disabled={loading} onClick={handleStartTrial} color="primary">
                        Start trial
                    </Button>
                )}
                <Link to="/pricing" className="vulners-link-initial">
                    <Button disabled={loading} color="primary">
                        Get license
                    </Button>
                </Link>
            </DialogActions>
        </Dialog>
    );
};

export default TrialLicenseDialog;

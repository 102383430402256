import React, { useCallback, useMemo, useState } from 'react';

import TrialLicenseDialog from 'pages/components/TrialLicenseDialog';

interface Options {
    validLicense: string[];
    currentLicense: string[];
}

export default function useTrialLicenseDialog() {
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState<Options | null>();

    const open = useCallback((options: Options) => {
        setOptions(options);
        setIsOpen(true);
    }, []);
    const close = useCallback(() => setIsOpen(false), []);

    const dialog = useMemo(
        () => (
            <TrialLicenseDialog
                open={isOpen}
                validLicense={options?.validLicense ?? []}
                currentLicense={options?.currentLicense ?? []}
                onClose={close}
            />
        ),
        [close, isOpen, options?.currentLicense, options?.validLicense]
    );

    return [dialog, open] as const;
}

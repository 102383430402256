import { makeStyles } from 'tss-react/mui';

import { MOBILE_BREAK_POINT } from 'app/constants';

export default makeStyles({ name: 'Insights' })((theme) => ({
    title: {
        margin: 0,
        color: '#232323',
        textAlign: 'center',
        fontSize: 48,
        fontWeight: 700,
        lineHeight: '120%',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            fontSize: 32,
            textAlign: 'start'
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 120,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            gap: 64
        }
    },
    list: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        rowGap: 80,
        columnGap: 32,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            gridTemplateColumns: '1fr',
            rowGap: 40
        }
    }
}));

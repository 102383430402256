import {observer} from 'mobx-react';
import React from 'react';
import {Link} from 'react-router-dom';

import {AccountCircleOutlined} from '@mui/icons-material';
import {Chip} from '@mui/material';

import { BOOK_LIVE_DEMO_URL } from 'app/constants';
import useStores from 'hooks/use-stores';

import useStyles from './styles';

import useIsMobile from '../../../../../../hooks/use-is-mobile';
import Logo from '../Logo';
import MenuDesktop from '../MenuDesktop';
import MenuMobile from '../MenuMobile';

interface Props {
    className?: string;
}

const Header: React.FC<Props> = ({className}) => {
    const {classes, cx} = useStyles();
    const {userStore} = useStores();
    const isMobile = useIsMobile();

    return (
        <div className={cx(classes.root, className)} data-testid={'header'}>
            <Logo className={classes.logo}/>
            {isMobile ? (
                <MenuMobile/>
            ) : (
                <>
                    <MenuDesktop className={classes.flex}/>
                    <div className={classes.actions}>
                        {userStore.isAuthorized ? (
                            <Chip
                                className={classes.login}
                                component={Link}
                                clickable
                                icon={<AccountCircleOutlined/>}
                                variant="outlined"
                                to={'/userinfo'}
                                label={userStore.user.username}
                            />
                        ) : (
                            <Chip
                                className={classes.login}
                                component={Link}
                                clickable
                                variant="outlined"
                                to={'/login'}
                                label={'Sign in'}
                                data-testid={'signIn'}
                            />
                        )}
                        <Chip
                            className={classes.demo}
                            component={'a'}
                            clickable
                            variant="filled"
                            href={BOOK_LIVE_DEMO_URL}
                            label={'Book a live demo'}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default observer(Header);

import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'TrialLicenseDialog' })({
    root: {
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        width: '160px',
        margin: '16px 0 16px 0'
    },
    license: {
        color: '#ff6600',
        listStyle: 'none',
        textTransform: 'capitalize'
    }
});

import { makeStyles } from 'tss-react/mui';

import { MOBILE_BREAK_POINT } from 'app/constants';

export default makeStyles({ name: 'ApiAdvertisingItem' })((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16
    },
    title: {
        color: '#FFF',
        fontSize: 48,
        fontWeight: 700,
        lineHeight: '120%',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            fontSize: 32,
            textAlign: 'start'
        }
    },
    subtitle: {
        color: '#FFF',
        fontSize: 24,
        fontWeight: 500,
        lineHeight: '150%',
        opacity: 0.65
    }
}));

import { makeStyles } from 'tss-react/mui';

import { MOBILE_BREAK_POINT } from 'app/constants';

export default makeStyles({ name: 'InfoGraphic' })((theme) => ({
    carousel: {
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            paddingLeft: 316
        }
    }
}));

import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { CircularProgress, IconButton } from '@mui/material';

import API from 'app/API';
import useStores from 'hooks/use-stores';
import { downloadFile } from 'utils/download-file';
import Utils from 'utils/Utils';

import DownloadIcon from './assets/download.svg';
import useStyles from './styles';

import useTrialLicenseDialog from '../../hooks/use-trial-license-dialog';

interface Props {
    type: string;
    name: string;
    count: number;
    className?: string;
}

const ERROR_CODE_WRONG_LICENSE = 140;

enum State {
    READY,
    LOADING,
    ERROR
}

const BulletinWidget: React.FC<Props> = ({ type, name, count, className }) => {
    const { classes, cx } = useStyles();
    const { userStore, dialogStore } = useStores();

    const [state, setState] = useState(State.READY);
    const [dialog, openTrialLicenseDialog] = useTrialLicenseDialog();

    const handleClick = useCallback(async () => {
        if (userStore.user.isAuthorized) {
            setState(State.LOADING);

            const response = await API.fetch(API.ARCHIVE_COLLECTION, { type }, true);
            const isJson = response.headers.get('content-type').match(/json/i);
            if (isJson) {
                const { data } = await response.json();
                if (data.errorCode === ERROR_CODE_WRONG_LICENSE) {
                    const { validLicense, currentLicense } = data.errorData;
                    openTrialLicenseDialog({ validLicense, currentLicense });
                } else {
                    setState(State.ERROR);
                }
            } else {
                const filename = response.headers.get('content-disposition').match(/filename=(.*)/i)[1];
                const blob = await response.blob();
                downloadFile(blob, filename);
            }
            setState(State.READY);
        } else {
            dialogStore.openLoginDialog();
        }
    }, [dialogStore, openTrialLicenseDialog, type, userStore.user.isAuthorized]);

    return (
        <div className={cx(classes.container, className)}>
            <img width={48} height={48} className={classes.image} src={Utils.getImage(type)} alt={type} />
            <div className={classes.info}>
                <a href={'/search/types/' + type}>
                    <span className={classes.name}>{name}</span>
                </a>
                <span className={classes.count}>{`${count} entries`}</span>
            </div>
            <IconButton
                color={state === State.ERROR ? 'error' : 'inherit'}
                className={classes.button}
                disabled={state !== State.READY}
                onClick={handleClick}
            >
                {state === State.LOADING ? (
                    <CircularProgress size={16} />
                ) : state === State.ERROR ? (
                    <ErrorOutlineOutlinedIcon />
                ) : (
                    <DownloadIcon />
                )}
            </IconButton>
            {dialog}
        </div>
    );
};

export default observer(BulletinWidget);

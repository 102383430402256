import { makeStyles } from 'tss-react/mui';

import { MOBILE_BREAK_POINT } from 'app/constants';
import macOsScrollbar from 'main/theme/mac-os-scrollbar';

export default makeStyles({ name: 'ApiReference' })((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 80,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            gap: 40
        }
    },
    content: {
        display: 'flex',
        alignItems: 'stretch',
        gap: 40,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            flexDirection: 'column',
            gap: 20
        }
    },
    references: {
        flex: 1,
        backgroundColor: '#232323',
        borderRadius: 24,
        order: 1,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            order: 2
        }
    },
    line: {
        display: 'flex',
        padding: 16
    },
    title: {
        margin: 0,
        fontSize: 48,
        fontWeight: 700,
        color: '#232323',
        textAlign: 'center',
        lineHeight: '120%',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            fontSize: 32,
            textAlign: 'start'
        }
    },
    code: {
        padding: 36,
        margin: '4px 8px 4px 4px',
        fontSize: 17,
        height: 400,
        ...macOsScrollbar({ color: '#535353', visible: 'always' }),
        ['& pre']: {
            margin: 0,
            whiteSpace: 'pre-wrap',
            overflowWrap: 'anywhere',
            ['& code']: {
                background: 'inherit'
            }
        },
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            fontSize: 12,
            padding: 12
        }
    },
    circles: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        marginRight: 'auto'
    },
    tabs: {
        margin: 'auto',
        padding: 4,
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        background: '#151515',
        borderRadius: 40
    },
    cases: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        order: 2,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            order: 1,
            flexDirection: 'row',
            maxWidth: '100%',
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
                display: 'none'
            }
        }
    }
}));
